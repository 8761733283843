import "../../css/Impressum.css";

const Impressum = () => {
    return (
        <>
            <div className={"impressumBox"}>
                <h1>Impressum</h1>

                <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                <p>Martin Hertel<br />
                    Am M&uuml;hlgraben 6<br />
                    04808 Wurzen</p>

                <h2>Kontakt</h2>
                <p>Telefon: 01602097989<br />
                    E-Mail: martin.hertel01@gmail.com</p>

                <p>Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a></p>
            </div>

        </>
    );
};

export default Impressum;