import '../../css/SpikyBall.css'
import { motion } from "framer-motion";

const SpikyBall = () => {
    return(
        <>
            <div className={"spikyHolder"}>
                <div className={"leftSide"}>
                    <motion.div className={"spikyBox1"}
                                animate={{x: [-500, 0]}}
                    >
                        <h1>Spiky Ball</h1>
                        <p>Obwohl dieses Spiel nicht das komplexeste Projekt ist, hat es einen Ehrenplatz auf meiner Seite verdient, da es das erste Spiel war, das ich im Alter von 15 Jahren programmiert habe.</p>
                    </motion.div>
                    <motion.div className={"spikyBox1"}
                                initial={{ opacity: 0, scale: 0.5 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.2 }}
                    >
                        <h2>Spielbeschreibung:</h2>
                        <p>
                            Es gibt eine unendliche Anzahl von prozedural generierten Leveln, bei denen der Spieler einen Ball auf einem Zylinder steuert und Hindernissen ausweichen muss, während er langsam beschleunigt, bis er ein maximales Tempo erreicht. Wenn der Spieler erfolgreich das Ende erreicht hat, gelangt er zum nächsten, längeren Level.
                        </p>
                    </motion.div>
                    <motion.div className={"spikyBox1"}
                                animate={{x: [-500, 0]}}
                    >
                        <h2>Technisches:</h2>
                        <p>
                            Basis hierfür war die Unity Engine. Das Spiel habe ich demnach in C# programmiert.
                        </p>
                    </motion.div>
                </div>
                <motion.div
                            animate={{x: [500, 0]}}
                >
                    <img src={require("../../images/spikyBall/SpikyBall1.png")} className={"spikyBox2"}/>
                </motion.div>
            </div>
        </>

    );
};

export default SpikyBall;