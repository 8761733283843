import '../../css/Contact.css'

const Contact = () => {
    return(
        <div id={"contact"}>
            <div className={"contactBox"}>
                <h2>Habe ich Ihr Interesse geweckt?</h2>
                <p>
                    Wenn Sie auf der Suche nach einem engagierten und zuverlässigen Werkstudenten oder Praktikanten sind, dann könnte ich die richtige Person für Sie sein. Meine Webseite hat Ihnen bereits einen Eindruck von meinen Fähigkeiten und Erfahrungen vermittelt. Sollten Sie Interesse an einer Zusammenarbeit haben oder weitere Informationen benötigen, stehe ich Ihnen jederzeit gerne zur Verfügung.
                </p>
                <p>
                    Ich suche derzeit gezielt nach einer neuen Herausforderung in Form eines Werkstudentenjobs oder Praktikums, um meine Fähigkeiten weiter auszubauen und praktische Erfahrungen zu sammeln. Sollten Sie eine entsprechende Möglichkeit in Ihrem Unternehmen sehen, würde ich mich sehr freuen, von Ihnen zu hören und mehr über Ihre Erwartungen und Anforderungen zu erfahren.
                </p>
                <div id={"buttonBox"}>
                    <a href="mailto:hertel.martin01@gmail.com">Kontakt</a>
                </div>

            </div>
        </div>
    );
};

export default Contact;