import '../../css/Home.css'
import About from "./About";
import Projects from "./Projects";
import { motion } from "framer-motion";
import Contact from "./Contact";

const Home = () => {
    return (
        <>
            <div id={"home"}>
                <motion.h1 className={"title"}
                           animate={{ x: [-100, 0]}}
                >Hi, ich bin Martin, <br/>Informatikstudent.</motion.h1>

                <motion.div className="description"
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                >
                    <p>Auf meinem Portfolio stelle ich stolz meine zahlreichen Projekte vor, bei denen ich mein Können in Web-Entwicklung, Software-Engineering und Spieleentwicklung unter Beweis gestellt habe. Wenn du an der Tech-Industrie interessiert bist oder einfach nur meine Arbeit bewundern möchtest, bist du hier genau richtig.</p>
                </motion.div>
            </div>
            <About/>
            <Projects/>
            <Contact/>
        </>
    );
};

export default Home;