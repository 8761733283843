import { HashLink } from 'react-router-hash-link';
import "../../css/Footer.css"

const Footer = () => {
    return(
        <footer>
            <div className={"footerContent"}>
                <p>Designed & built by Martin Hertel</p>
                <p><HashLink to={ "/Impressum" }>Impressum</HashLink></p>
            </div>
        </footer>
    );
};

export default Footer;