import "../../css/Projects.css"
import { motion } from "framer-motion";
import {Link} from "react-router-dom";

const Projects = () => {
    return (
      <div className={"projectsBox"} id={"projects"}>
          <motion.h1 className={"aboutTitle"}
                     animate={{ x: [-100, 0] }}
                     transition={{ delay: 0.1 }}
          >
              Nennenswerte Projekte:
          </motion.h1>
            <Link to="/braintumor">
                <motion.div className={"projects"}
                          transition={{ duration: 0.3 }}
                          animate={{ x: [-500, 0] }}
                          whileHover={{
                              scale: 1.05
                          }}
                >
                  <div className={"innerPDesc"}>
                      <h2>Hirntumor Segmentierung</h2>
                      <p>Ich habe kürzlich ein Projekt abgeschlossen, bei dem ich ein neuronales Netz, genauer ein U-Net, modelliert habe, um Hirntumore auf MRT-Bildern einzufärben. Mit dieser Methode können die Tumore deutlich sichtbarer gemacht werden.</p>
                      <span className={"foot"}><b>Verwendete Programmiersprachen / Libraries: </b>Python, Tensorflow, Keras</span>
                  </div>
                  <div className={"innerPImg"}>
                      <img className="brainImage" src={require("../../images/braintumor/braintumor_mask.png")}/>
                  </div>
                </motion.div>
                </Link>

                <Link to="/catbreed">
                <motion.div className={"projects"}
                          transition={{ duration: 0.3 }}
                          animate={{ x: [500, 0] }}
                          whileHover={{
                              scale: 1.05
                          }}
                >
                    <div className={"innerPDesc"}>
                      <h2>Katzenrassen Erkennung</h2>
                      <p>Katzenrassenerkennung mit einem Convolutional Neural Network (CNN) und Transfer Learning durch das InceptionV3-Modell</p>
                        <span className={"foot"}><b>Verwendete Programmiersprachen / Libraries: </b>Python, Tensorflow, Keras</span>
                    </div>
                    <div className={"innerPImg"}>
                        <img className="catBreedImage" src={require("../../images/catbreed/CatBreed1.jpg")}/>
                    </div>
                </motion.div>
            </Link>

            <Link to="/spikyball">
                <motion.div className={"projects"}
                            transition={{ duration: 0.3 }}
                            animate={{ x: [-500, 0] }}
                            whileHover={{
                                scale: 1.05
                            }}
                >
                    <div className={"innerPDesc"}>
                      <h2>Spiky Ball</h2>
                      <p>Ein simples Spiel mit prozedural generierten Leveln, bei dem der Spieler einen Ball auf einem Zylinder steuert und Hindernissen ausweicht.</p>
                        <span className={"foot"}><b>Verwendete Programmiersprachen / Programme: </b>C#, Unity</span>
                    </div>
                    <div className={"innerPImg"}>
                        <img className="spikyBallImage" src={require("../../images/spikyBall/SpikyBall2.png")}/>
                    </div>
                </motion.div>
            </Link>
      </div>
    );
};

export default Projects;