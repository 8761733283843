import '../../css/Braintumor.css'
import {motion} from "framer-motion";
const Braintumor = () => {
    return (
        <>
            <div className={"container"}>
                <h1>Hirntumor Segmentierung</h1>
                <h2 id={"Github"}><a href={"https://github.com/Martinh779/Braintumor-Segmentation"}>Link zum Github Projekt</a></h2>
                <div className={"box1"}>
                    <div className={"brainHolder"}>
                        <motion.div className={"brainBox3"}
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.5 }}
                        >
                            <h2>Beschreibung:</h2>
                            <p>
                                In diesem Projekt habe ich ein neuronales Netzwerk entwickelt, das in der Lage ist, MRT-Bilder von Hirntumoren zu analysieren und diese, sofern vorhanden, zu identifizieren und zu markieren. Hierdurch wird die Identifikation von Tumoren erheblich erleichtert. Das Modell basiert auf der Architektur eines sogenannten U-NET, das in der Lage ist, komplexe Muster in den Eingangsbildern zu erkennen und zu klassifizieren.
                            </p>
                        </motion.div>
                        <motion.div className={"brainBox1"}
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.5, delay: 0.1 }}
                        >
                            <h2>Datensatz:</h2>
                            <p>
                                Das Modell wurde auf einem Datensatz von etwa 5000 MRT-Bildern von Gehirnen und den dazugehörigen Masken trainiert, wobei etwa 60% der Bilder einen Tumor enthielten und 40% Standardaufnahmen darstellten. Obwohl es eine leichte Disbalance zwischen den beiden Klassen gab, hatte dies keinen ausschlaggebenden Einfluss auf die Ergebnisse.
                            </p>
                        </motion.div>
                        <motion.div className={"brainBox3"}
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.5, delay: 0.2 }}
                        >
                            <h2>Funktionsweise:</h2>
                            <p>
                                Die Funktionsweise des Modells ist wie folgt: Im ersten Schritt wurden die Daten vorbereitet, indem der Datensatz in ein Trainings- und Validierungsset aufgeteilt wurde. Das Trainingsset umfasste 80% der Daten. Die Bilder wurden in Graustufen umgewandelt, um Speicherplatz und Rechenleistung zu sparen, da MRT-Bilder in der Regel ohnehin schwarz-weiß sind.

                                Im zweiten Schritt wurde das neuronale Netz modelliert, wobei eine U-Net-Architektur gewählt wurde, da diese Architektur exzellente Ergebnisse bei Segmentierungen liefert.

                                Im dritten Schritt wurde das Modell auf den Datensatz trainiert. Da es sich um ein U-Net handelt, besteht es aus einem Encoder und einem Decoder. Der Encoder zerlegt das eingegebene MRT-Bild in kleinere Bestandteile. Dies wird durch sogenannte Convolutional Layer erreicht, die Muster auf einem Bild durch Filter erkennen können. Hierdurch wird das Bild kleiner, die Dimensionen jedoch größer, was auch als Downsampling bezeichnet wird. Das Modell lernt hierdurch, ob es sich bei dem Bildausschnitt um einen Tumor handelt oder nicht.

                                Um die Information über den Tumorstandort wiederherzustellen, wird der Decoder eingesetzt. Das stark reduzierte Bild mit hoher Dimensionalität wird durch Upsampling, d.h. Hochskalierung, wieder vergrößert. Hierfür wurden Transpose-Layer verwendet. Durch diesen Prozess lernt das Modell, wo sich der Tumor im Bild befindet, indem jeder Pixel des Bildes klassifiziert wird. Die Ausgabe des Modells ist eine schwarz-weiß Maske, die den Tumor lokalisiert. Diese Maske wird mit der Maske aus dem Datensatz abgeglichen, um die Fehlerquote zu verringern und die Genauigkeit zu erhöhen.
                            </p>
                        </motion.div>
                        <motion.div className={"brainBox1"}
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.5, delay: 0.3 }}
                        >
                            <h2>Ergebnisse:</h2>
                            <p>
                                Die beiden Kurven im Ergebnisdiagramm stehen jeweils für die Genauigkeit des Modells nach jedem Durchgang (insgesamt 20) und dem Verlust (loss). Wie man sehen kann, verlaufen die beiden Kurven fast identisch, was bedeutet, dass das Modell nicht überangepasst (overfitted) ist und daher auch Daten, die es vorher noch nicht gesehen hat, genau so gut klassifizieren kann wie bereits gesehene Daten.

                                Das Modell wurde mit einer Genauigkeit von 98% evaluiert und könnte somit als zuverlässiges Instrument zur Erkennung von Hirntumoren eingesetzt werden.
                            </p>
                        </motion.div>
                    </div>
                </div>
                <div className={"box2"}>
                    <h2>Einige Beispielausgaben</h2>
                    <div className={"exampleBox"}>
                        <div className={"titleHolder"}>
                            <span className={"left"}>MRT Bild</span>
                            <span className={"middle"}>Ausgegegbene Maske</span>
                            <span className={"right"}>Vorhergesagtes Bild</span>
                        </div>
                        <img className="exampleImage" src={require("../../images/braintumor/Prediction1.png")}/>
                    </div>
                    <div className={"exampleBox"}>
                        <div className={"titleHolder"}>
                            <span className={"left"}>MRT Bild</span>
                            <span className={"middle"}>Ausgegegbene Maske</span>
                            <span className={"right"}>Vorhergesagtes Bild</span>
                        </div>
                        <img className="exampleImage" src={require("../../images/braintumor/Prediction2.png")}/>
                    </div>
                    <div className={"exampleBox"}>
                        <div className={"titleHolder"}>
                            <span className={"left"}>MRT Bild</span>
                            <span className={"middle"}>Ausgegegbene Maske</span>
                            <span className={"right"}>Vorhergesagtes Bild</span>
                        </div>
                        <img className="exampleImage" src={require("../../images/braintumor/Prediction3.png")}/>
                    </div>
                    <div className={"exampleBox"}>
                        <div className={"titleHolder"}>
                            <span className={"left"}>MRT Bild</span>
                            <span className={"middle"}>Ausgegegbene Maske</span>
                            <span className={"right"}>Vorhergesagtes Bild</span>
                        </div>
                        <img className="exampleImage" src={require("../../images/braintumor/Prediction4.png")}/>
                    </div>
                    <div className={"exampleBox"}>
                        <div className={"titleHolder"}>
                            <span className={"left"}>MRT Bild</span>
                            <span className={"middle"}>Ausgegegbene Maske</span>
                            <span className={"right"}>Vorhergesagtes Bild</span>
                        </div>
                        <img className="exampleImage" src={require("../../images/braintumor/Prediction5.png")}/>
                    </div>
                    <div className={"exampleBox"}>
                        <div className={"titleHolder"}>
                            <span className={"left"}>MRT Bild</span>
                            <span className={"middle"}>Ausgegegbene Maske</span>
                            <span className={"right"}>Vorhergesagtes Bild</span>
                        </div>
                        <img className="exampleImage" src={require("../../images/braintumor/Prediction6.png")}/>
                    </div>
                    <div className="modelStats">
                        <h2>Modell Daten</h2>
                        <img src={require("../../images/braintumor/model_stats.png")} className={"modelImg"}/>
                    </div>
                </div>


            </div>
        </>
    );
};

export default Braintumor;