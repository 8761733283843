import '../../css/CatBreed.css'
import {motion} from "framer-motion";
const CatBreed = () => {
    return(
        <>
            <div className={"catHolder"}>
                <motion.div className={"catBox1"}
                            animate={{x: [-500, 0]}}
                >
                    <h1>Katzenrassenerkennung mit einem Convolutional Neural Network (CNN) und Transfer Learning durch das InceptionV3-Modell</h1>
                </motion.div>
                <motion.div className={"catBox1"}
                            animate={{x: [500, 0]}}
                >
                    <h2>Datensatz:</h2>
                    <p>
                        Der Datensatz enthält insgesamt 29 verschiedene Katzenrassen. Ich habe mich auf diese 29 Rassen beschränkt, um die Genauigkeit des Modells zu erhöhen und Overfitting zu vermeiden. Der Datensatz enthält rund 6000 Bilder und die Bilder haben eine Auflösung von 256x256 Pixel.
                    </p>
                </motion.div>
                <motion.div className={"catBox1"}
                            animate={{x: [-500, 0]}}
                >
                    <h2>CNN-Architektur:</h2>
                    <p>
                        Für mein CNN habe ich mich für eine klassische Architektur entschieden, die aus mehreren Convolutional-Layern, Pooling-Layern und Fully-Connected-Layern besteht. Ich habe mich für eine Architektur mit vier Convolutional-Layern entschieden, gefolgt von zwei Pooling-Layern und drei Fully-Connected-Layern. Die Architektur wurde durch Versuche optimiert und ich habe mich für eine ReLU-Aktivierungsfunktion entschieden.
                    </p>
                </motion.div>
                <motion.div className={"catBox1"}
                            animate={{x: [500, 0]}}
                >
                    <h2>Transfer Learning durch InceptionV3:</h2>
                    <p>
                        Um die Genauigkeit meines Modells zu verbessern, habe ich Transfer Learning durch das InceptionV3-Modell von Google vorgenommen. InceptionV3 ist ein tiefes CNN, das auf der Imagenet-Datenbank trainiert wurde und exzellente Ergebnisse bei der Bildklassifizierung liefert. Ich habe die vortrainierten Gewichte von InceptionV3 als Ausgangspunkt genommen und das Modell auf meine spezifischen Katzenrassen umtrainiert.
                    </p>
                </motion.div>
                <motion.div className={"catBox1"}
                            animate={{x: [-500, 0]}}
                >
                    <h2>Transfer Learning durch InceptionV3:</h2>
                    <p>
                        Um die Genauigkeit meines Modells zu verbessern, habe ich Transfer Learning durch das InceptionV3-Modell von Google vorgenommen. InceptionV3 ist ein tiefes CNN, das auf der Imagenet-Datenbank trainiert wurde und exzellente Ergebnisse bei der Bildklassifizierung liefert. Ich habe die vortrainierten Gewichte von InceptionV3 als Ausgangspunkt genommen und das Modell auf meine spezifischen Katzenrassen umtrainiert.
                    </p>
                </motion.div>
                <motion.div className={"catBox1"}
                            animate={{x: [500, 0]}}
                >
                    <h2>Training und Evaluierung:</h2>
                    <p>
                        Ich habe meinen Datensatz in einen Trainings- und Testdatensatz aufgeteilt, wobei der Trainingsdatensatz 80% der Bilder enthält und der Testdatensatz 20%. Ich habe das Modell mit dem Trainingsdatensatz trainiert und auf dem Testdatensatz evaluiert. Zur Optimierung der Hyperparameter habe ich ein Grid Search durchgeführt und die besten Parameter ausgewählt. Die Metrik, die ich zur Bewertung meines Modells verwendet habe, war die Accuracy, also die Genauigkeit.
                    </p>
                </motion.div>
                <motion.div className={"catBox1"}
                            animate={{x: [-500, 0]}}
                >
                    <h2>Ergebnisse:</h2>
                    <p>
                        Mein Modell hat auf dem Testdatensatz eine Genauigkeit von 47% erreicht. Das bedeutet, dass es in 47% der Fälle die richtige Katzenrasse aus den 29 vorgegebenen Rassen erkannt hat. Diese Ergebnisse sind sehr eher semit optimal, aber es wäre durchaus möglich gewesen die Genauigkeit um ein vielfaches zu erhöhen, indem man einen größeren Datensatz verwendet hätte.
                    </p>
                </motion.div>
                <motion.div className={"catBox1"}
                            animate={{x: [-500, 0]}}
                >
                    <h2>Ergänzung:</h2>
                    <p>
                        Ich habe das Modell in ein Tensorflow Lite Modell umgewandelt und eine Android-App in Android Studio entwickelt, die es Benutzern ermöglicht, ein neues Bild auszuwählen und schnell zu überprüfen, zu welcher Katzenrasse das Bild gehört.
                    </p>
                </motion.div>
            </div>
            <motion.div
                        animate={{x: [500, 0]}}
            >
                <img src={require("../../images/catbreed/CatBreed1.jpg")} className={"catBox2"}/>
            </motion.div>
        </>
    );
};

export default CatBreed;