import '../../css/About.css'
import { motion } from "framer-motion";

const About = () => {
    return (
        <>
            <motion.div className="informationBox" id={"about"}
                      animate={{ scale: [0.5, 1.0]}}
                      transition={{ duration: 0.5 }}
            >
                <h2 className="aboutTitle">Über mich</h2>
                <div className="about">
                    <p className="descriptionAbout">
                        Ich heiße Martin und bin seit meiner Kindheit begeistert von der Welt der Computer. Schon früh begann ich damit, meine eigenen Spiele zu entwickeln, und diese Leidenschaft hat mich bis heute nicht losgelassen. Inzwischen beschäftige ich mich intensiv mit Themen wie neuronalen Netzen und machinellem Lernen, und habe bereits umfassende Erfahrungen in verschiedenen Programmiersprachen gesammelt.

                        <br/>
                        <br/>
                        So habe ich beispielsweise umfangreiche Kenntnisse in Java und Python, wobei ich letztere Sprache auch in Verbindung mit Keras und Tensorflow nutze. Darüber hinaus bin ich auch mit C++, C# und der Unreal Engine vertraut. Insgesamt betrachtet verfüge ich also über ein breites Spektrum an Fähigkeiten und Kompetenzen im Bereich der Softwareentwicklung.

                        <br/>
                        <br/>
                        Ich bin davon überzeugt, dass meine Leidenschaft und meine Erfahrung mir dabei helfen können, in Zukunft noch größere Projekte zu realisieren und innovative Lösungen zu finden. Daher freue ich mich darauf, meine Kenntnisse in diesem Bereich weiter auszubauen und immer wieder neue Herausforderungen anzunehmen.
                    </p>
                    <div className="profileImageBox">
                        <img className="profileImage" src={require("../../images/Bild.JPEG")}/>
                    </div>
                </div>
            </motion.div>
        </>

    );
};

export default About;